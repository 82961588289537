html {
     height: 100vH;
     width: 100vW;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Roboto !important';
    font-size: 16px;
  }
  
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vH;
}

main {
   display: flex;
   flex-direction: column;
   flex-grow: 1;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
}